import React, { useState, useEffect } from 'react';
import { Heading } from './Home.styles';
import { useTranslation } from 'react-i18next';
import {HomePageWrapper, Introduction, WelcomeText, StyledLogo, CursorStyle,Airplane} from './Home.styles';
import Logo from '../Footer/Logo';
import plane from '../../assets/images/airplane.png';


const Home = () => {
  const { t, i18n } = useTranslation();
  const [HeaderText, setHeaderText] = useState('');
  const [CompleteHeaderText,setCompleteHeaderText] = useState('   Welcome to my portfolio!');
  const [CursorShowing,setCursorShowing] = useState(true);
  

  useEffect(() => {
  
    let index = 0;
    
    const startDelay = 0; 
    
    const delayTimeout = setTimeout(() => {
      const interval = setInterval(() => {
        setHeaderText(prevText => prevText + (CompleteHeaderText[index]||''));
        index++;
        if (index === CompleteHeaderText.length - 1) {
          setCursorShowing(false);
          clearInterval(interval);
        }
      }, 100);
  
    return () => clearInterval(interval);
  }, startDelay);

  return () => clearTimeout(delayTimeout);
  }, []);
  
  const [IntroductionText, setIntroductionText] = useState('');
  const CompleteIntroductionText = t('home.introduction');

  useEffect(() => {
    let index = -1;
    
    const startDelay = 5000; 
    
    const delayTimeout = setTimeout(() => {
      const interval = setInterval(() => {
        setIntroductionText(prevText => prevText + CompleteIntroductionText[index]);
        index++;
        if (index === CompleteIntroductionText.length-1) clearInterval(interval);
      }, 50);
  
      return () => clearInterval(interval);
    }, startDelay);
  
    return () => clearTimeout(delayTimeout);
  }, []);

  return (
    <HomePageWrapper>
      <Airplane src={plane}/>
      <WelcomeText>{HeaderText}{(CursorShowing)?(<><CursorStyle/></>):(<></>)}</WelcomeText>
      <Introduction>{IntroductionText}</Introduction>
    </HomePageWrapper>
  );
};

export default Home;
