import styled from 'styled-components';

export const Header = styled.header`
font-size: 32px;
font-weight: bold;
color: #29A0B1;
margin-bottom: 20px;
margin-left:2vw;
`;

export const Title = styled.h1``;

export const Filter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const FilterButton = styled.button`
  background-color: #98D7C2;
  border: none;
  color: #167D7F;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #29A0B1;
    color: #DDFFE7;
  }
`;

export const PortfolioGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box; 
  @media(max-width:600px){
    margin-left:10px;
  }

`;

export const ProjectCard = styled.div`
  width: 45vw;
  background-color: #29A0B1;
  padding: 15px;
  color: #DDFFE7;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box; 
  @media(max-width:600px){
    width:100vw;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height:50vh;
  border-radius: 5px;

  @media(max-width:600px){
    height:auto;
  }
`;

export const CardTitle = styled.h1`
  margin: 10px 0;
`;

export const CardExcerpt = styled.p`
  margin-bottom: 10px;
`;

export const CardLink = styled.a`
  margin-bottom: 10px;
  color:#ddffe7;
  display: block;
  max-width: 100%; // Add this line
  overflow-wrap: break-word; // Add this line
  word-wrap: break-word;
`;
export const CardTechnologies = styled.li`
  margin-bottom: 10px;
`;
export const TechTitle = styled.h4`
  margin-bottom: 10px;
`;