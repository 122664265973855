import styled, { css } from 'styled-components';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';
import { Button } from 'react-bootstrap';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width:100vw;
  height: 5vw;
`;

export const Heading = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  text-align: justify;
`;

export const StyledNavbar = styled(Navbar)`
  position:fixed;
  background-color:#29A0B1;
  color:white;
  padding-left:20px;
`;
export const CollapseStyled = styled(Navbar.Collapse)`
  text-align:center;
  width:100%;
  display:flex;
  justify-content:space-around;
  margin: 0px 20px;
  @media(max-width:700px){
    flex-direction:column;
  }
`;
export const BrandStyle = styled (Navbar.Brand)`
  cursor: pointer;
  color:var(--Navigation-text-color);
  &:hover {
    color: #98D7C2;
  }
`;
export const StyledLink = styled (Nav.Link )`
  color:var(--Navigation-text-color);
  margin-right: 2vw;
  font-size:1.5rem;
  &:hover {
    color: #98D7C2;
    font-size:1.25rem;
  }
`;
export const Globe = styled (FaGlobe )`
  color:var(--Navigation-text-color);
`;
export const DropDownStyled = styled(Dropdown.Toggle )`

  color:var(--Navigation-text-color);
  border-width:0px;
  &:hover {
    background-color: #98D7C2;
  }
`;
export const DropDownMenuStyled = styled(Dropdown.Menu)`
  color:var(--Navigation-text-color);
  background-color:#167D7F;
`;
export const DownloadButton = styled(Button)`
  color:var(--Navigation-text-color);
  background-color: #29A0B1;
  border: 1px solid white;

  &:hover {
    background-color: #98D7C2;
  }
`;
export const StyledNavbarToggle = styled(Navbar.Toggle)`
color:white;
border-color:white;
$:focus,
&:active{
  color: white;
  border-color: white;
  outline: none;
  box-shadow: none;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
`;
export const StyledDropdownItem = styled(Dropdown.Item )`
  color:var(--Navigation-text-color);
` ;
export const DownloadAndDropdownSection = styled.div`
  display: flex;
  flex-direction: row;
`;

