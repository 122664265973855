import React from 'react';
import {
  AboutMeContainer,
  LeftColumn,
  ProfilePicture,
  SocialIcons,
  SocialIcon,
  RightColumn,
  AboutMeTitle,
  AboutMeText,
  SectionTitle,
  TextOnlyDesktop,
  ProfilePictureWrapper,
  InformationWrapper
} from './AboutMe.styles';
import Ahmad from '../../assets/images/ahmad.png';
import { useTranslation } from 'react-i18next';


const AboutMe: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <AboutMeContainer>
      <LeftColumn>
        <ProfilePictureWrapper>
          <ProfilePicture src={Ahmad} alt="Your Name" />
        <SocialIcons>
          <SocialIcon href="www.linkedin.com/in/ahmad-amine" target="_blank" rel="noopener noreferrer">
          LinkedIn
          </SocialIcon>
          <SocialIcon href="https://github.com/AhmadElAmine123" target="_blank" rel="noopener noreferrer">
            Github
          </SocialIcon>
        </SocialIcons>
        </ProfilePictureWrapper>
      </LeftColumn>
      <RightColumn>
        <InformationWrapper>
          <AboutMeTitle>{t('about.title')}</AboutMeTitle>
          <AboutMeText>
            {t('about.intro')}
          </AboutMeText>
          </InformationWrapper>

          <InformationWrapper>
          <AboutMeTitle>Experience</AboutMeTitle>
          <AboutMeText>
            {t('about.experience.geek')}
          </AboutMeText>

          </InformationWrapper>
          <InformationWrapper>
          <TextOnlyDesktop>
            {t('about.experience.linkit')}
          </TextOnlyDesktop>
          <AboutMeText>
            {t('about.freelance')}
          </AboutMeText>

        </InformationWrapper>
        
      </RightColumn>
    </AboutMeContainer>
  );
};

export default AboutMe;
