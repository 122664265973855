import styled, { keyframes }  from 'styled-components';
import {PageWrapper} from '../../components/shared/PageWrapper';
import laptop from '../../assets/images/laptop.svg';
import transparentLatop from '../../assets/images/laptopnobg.svg';
import Logo from '../Footer/Logo';

let animation_time = 4;
let added_text_size = 1;
let newHeaderTextSize = 2 + added_text_size; 
let animation_delay = 5;// the amount time it takes for the picture and text to start changing size and moving
let windowWidth= window.innerWidth;
let isMobile = false;
if (window.innerWidth < 700) {
  newHeaderTextSize = 3 + added_text_size; // Change the value for smaller screens
}
if(windowWidth<700){
  isMobile = true;
}


//the following is for the plane:
const planeFadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const PlaneMovementAnimation = keyframes`
  0% {
    right:${(isMobile)?(-20):(5)}vw;
  }
  69% {
    right:${(isMobile)?(30):(67)}vw;
  }
  100% {
    right: 100vw;
  }
`;
const PlaneRotation = keyframes`
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const typingAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const animateBackgroundSize = keyframes`
  from {
    background-size: 100%;
  }
  to {
    background-size: 40%;
  }
`;

const animatBackgroundImageChange = keyframes`
from {
  background-image: url(${transparentLatop});
}
to {
  background-image: url(${laptop});
}
`;

const animateBackgroundPosition = keyframes`
  from {
    background-position: center;
  }
  to {
    background-position: right;
  }
`;
const animateTextSize = keyframes`
  0%{
    font-size: 2rem;
  }
  20%{
    font-size: 2rem;
  }
  100%{
    font-size: ${newHeaderTextSize}rem;
  }
`;
const animateTextMovement = keyframes`
  from {
    left:${(isMobile)?('11.5vw'):('31vw')};
    top:${(isMobile)?('34vw'):('')};
  }
  to{
    left:0vw;
    top:${(isMobile)?('2vw'):('')};
  }`;
const colorChange = keyframes`
  from {
    color: #167D7F; /* Initial color */
  }

  to {
    color: white; /* Final color */
  }
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;
`;

export const Heading = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  text-align: justify;
`;

// Add more styled components as needed
export const HomePageWrapper = styled(PageWrapper)`
  width:100%;
  height: 100vh;
  overflow: hidden;
  background-image:url(${transparentLatop});
  background-repeat: no-repeat;
  background-size:100%;
  background-position:center;
  background-color:#29A0B1;
  padding-top:49vh;
  padding-left:50px;
  animation:
  ${animateBackgroundSize} ${animation_time-2}s linear forwards,
  ${animateBackgroundPosition} ${animation_time-2}s linear forwards,
  ${animatBackgroundImageChange} 2s linear forwards;
  animation-delay: ${animation_delay}s; 
  
  @media(max-width: 700px){
    padding-top:15vh;
    background-size:200%;
    padding-left:10px;
  }
`;
export const WelcomeText = styled.h1`
  position: relative;
  left:28.7vw;
  font-size:1.8rem;
  font-weight:bold;
  color:#167D7F;
  @media(max-width:700px){
    left:11.5vw;
    top: 34vh;
    font-size:1.1rem;
  }
  animation:
  ${animateTextSize} 2s linear forwards,
  ${animateTextMovement} 2s linear forwards,
  ${colorChange} 0.5s linear forwards
  ;
  animation-delay: ${animation_delay}s; 
`;
export const Introduction = styled.p`
  font-size:1.5rem;
  color:white;
  width:60vw;
  @media(max-width: 700px){
    font-size:1.1rem;
  }
`;
export const StyledLogo = styled.div`
  position: absolute;
  bottom: 0vh;
  left: 10vh;
`;
export const CursorStyle = styled.div`
  display: inline-block;
  height: 1em;
  width: 0.1rem;
  background-color: black;
  animation: ${typingAnimation} 0.4s infinite;
`;
export const Airplane = styled.img`
  opacity: 0;
  width:200px; 
  height:200px;
  top: 38vh;
    transform: rotate(-20deg);
  animation: ${planeFadeInOut} 6s ease-in-out forwards alternate,
   ${PlaneMovementAnimation} 4s ease-in-out  forwards alternate; 
   animation-delay:2.2s;
  position: absolute;
  z-index: 20;

  @media(max-width: 700px){
    top:70vh;
  }

`;