import React, { useEffect, useState } from 'react';
import { Container,
  SkillName,
  ProgressBarContainer,
  ProgressBar,
  SkillLevel,
  SkillInfo } from './SkillBar.styles';

interface Skill {
  name: string;
  level: number;
  logo: string;
}

interface SkillBarProps {
  skill: Skill;
  animate: boolean;
}

const SkillBar: React.FC<SkillBarProps> = ({ skill, animate }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (animate) {
      setProgress((skill.level / 5) * 100);
    } else {
      setProgress(0); 
    }
  }, [animate, skill.level]);

  return (
    <Container>
      <SkillInfo>
      <SkillName>{skill.name}</SkillName>
      <SkillLevel>Level: {skill.level}</SkillLevel>
      </SkillInfo>
      <ProgressBarContainer>
        <ProgressBar progress={progress} animate={animate} />
      </ProgressBarContainer>
    </Container>
  );
};

export default SkillBar;
