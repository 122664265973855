import styled,{ createGlobalStyle,css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  ${css`
  @import url('https://fonts.googleapis.com/css2?family=Texturina:ital,opsz,wght@1,12..72,100&display=swap');
  `}
  body {
    font-family: 'Texturina', serif;
    font-weight: 500;
    overflow-x: hidden;
  }
  --Navigation-text-color: white;
  `;
export const PageWrapper = styled.div`
  width:100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow: clip;
  display: overlay;
`;
